.koroLogoHeader {
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;
  display: block;
}

.backgroundColorBodee {
  --ion-background-color: #e8f3f191;
}

.welcomeKoroTitle {
  font-size: 1.5rem;
}

.accountSetupMoreInfo {
  color: #78797a;
  margin-bottom: 10px;
  text-align: center;
}

.subTitle {
  color: #78797a;
  text-align: left;
}

.ionPaddingTopMedium {
  padding-top: 40px;
}

.ionPaddingTopSmall {
  padding-top: 10px;
}

.inputItemForm {
  border: 1px solid #3e3e3e4e;
  border-radius: 20px;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.koroOnboardingContainer
{
  padding: 0 20px;
  max-width: 600px;
  margin: 0 auto;
}

.ionSelect
{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}


.profileAvatarIcon {
  width: 40px;
  height: 40px;
}

.notifIcon {
  color: white;
}

.sortChip {
  margin-right: 10px;
  padding: auto;
  width: 100px;
  justify-content: center;
}

.sortChipSecondary {
  padding: auto;
  width: 110px;
  justify-content: center;
}

.centerChips {
  margin: 0 auto;
}
