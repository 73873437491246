.koroQuestionCard {
    background-color: #e8f3f191;
    border-radius: 15px;
    margin-bottom: 40px;
}




.koroQuestionTitle {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px;
}

.bold {
    font-weight: bold !important;
    display: inline-block;
}




.test {
    height: 40px;
}

.inputItemFormComment {
    border: 1px solid #3e3e3e4e;
    border-radius: 20px;
    padding-left: 20px;
}

.createCommentRowElement{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}