
.koroQuestionHeaderItem {
    --ion-background-color: #e8f3f100;
}

.koroQuestionAvatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.koroQuestionCategory {
    color: #58bca4;
}