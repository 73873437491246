.slideTitle {
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.slideSubTitle {
  padding-left: 20px;
  padding-right: 20px;
}

.aboutCardTitle {
  font-size: 15px;
}

.subTitle {
  font-size: 13px;
  padding-left: 20px;
}

.questionP {
  font-size: 14px;
}

.mainGoalItem {
  margin: 2px;


  --border-color: #456673;
  --border-style: solid;
  --border-width: 1px;

  --border-radius: 10px;

}

.pickedItem {
  --border-color: #456673;
  --border-style: solid;
  --border-width: 1px;
  --background: #456673;
  color: white;
}

.checkPadding {
  padding-right: 15px;
}

.wellnesSlideTitle {
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.loaderPadding {
  padding-top: 50%;
}

.firstButton {
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 20px;
}

.appearanceButton {
  margin-top: 250px;
  margin-right: 20px;
  margin-left: 20px;
}

.progessbar {
  --background: #caaeb0;
  --progress-background: #456673;
}

.centerOfScreen{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.successCheckBox
{
  font-size: 90px;
}