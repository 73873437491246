/* body {
  text-align: center;
  padding-top: 2rem;
} */

.login-card {
  max-width: 50em;
}

.error {
  color: red;
}

.login-card {
  max-width: 500px;
}

.login-centered {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginLogo
{
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputItem {
  border: 1px solid #3e3e3e4e;
  border-radius: 20px;
  padding-left: 20px;
  margin: 20px;
  width: 300px;
}


.loginLogo
{
  width: 200px;
  margin: 0 auto;
}

.forgotPasswordLink
{
  color: #3e3e3e;
  font-weight: bold;
  margin: 20px;
  text-align: center;
}

.loginError
{
  color: rgb(255, 0, 0);
  margin: 20px;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;


}

.ionPaddingTopLarge
{
  padding-top: 80px;
}


.formHelp {
  font-size: 12px;
  color: rgb(244, 0, 0);
  margin-top: 5px;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}