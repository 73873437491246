.header-image {
    width: 125px;
    padding-top: 15px;;
}

.instr-wrapper {
    border: 2px solid #8fa3ab;
    border-radius: 5px;
    padding: 15px;
    margin : 20px;
}

.step {
    font-weight: bold;
    font-size: 15px;
    color: #456673;
}

.step-title {
    font-weight: bold;
    color: #456673;
}

.wording {
    font-size: 12px;
}

.disabled {
    color: #8fa3ab;
}

