.icon_plus {
  font-size: 35px;
}

.mealLogCover {
  object-fit: cover;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ionLogItem {
  padding-top: 10px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.dateToolbar {
  border-style: none;
  border-color: none;
  border-width: none;
  --background: transparent;
  --ion-color-base: transparent !important;
}

.date {
  font-size: 20px;
  text-align: center;
}

.center-data {
  text-align: center;
}

.journal-head {
  color : #fff;;
}

.journal-card {
  max-width: 550px;
}

.icon {
  padding-left: 10px;
}

.journal-container {
  background-color: blue;
}

.journal-outer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.journal-inner {
  width: 450px;
}
