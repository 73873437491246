.session-card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    
  }
  
  .session-cancel {
    text-decoration: underline;
    margin-bottom: 5px;
  }
  
  .wrapperSettingsHeaderImg {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: auto;
  }
  
  .wrapperSettingsHeaderImg img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .join-session-button
  {
  font-size: 15px;
  }

  .settingsHeaderCardName {
    font-size: 25px !important;
    font-weight: bold;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .settingsHeaderCardEmail {
    font-size: 18px !important;
    color: #727272;
    padding-bottom: 10px;
  }

  .settingsHeaderButton {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 18px;
  }