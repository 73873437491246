.loginManageSub {
  width: 150px;
  margin-top: 0px;
}

.subButtonPremium {
  /* //make letters spaced farrter apart  */
  letter-spacing: 0.5px;
  --color: var(--ion-color-primary);
  --background: white;
}

.subButtonBasic {
  /* //make letters spaced farrter apart  */
  letter-spacing: 0.5px;
}

.bold {
  font-weight: bold;
}

.price {
  font-size: 33px;
}

.featureListPremium {
  line-height: 25px;
  color: white;
}

.featureListBasic {
  line-height: 25px;
}

.packageSubTitle{
  font-size: 12px !important;
  color: white;
}
