.goal {
  --border-radius: 10px;
  margin-top: 8px;
  font-size: 15px;
  max-width: 500px;
}

.need-goal {
  --border-color: #456673;
  --border-radius: 10px;
  --border-width: 1px;
  --color: #456673;
}



.goal-input {
  border-radius: 10px;
  margin-bottom: 5px;
  font-size: 12px;
}

.ion-accordion-toggle-icon {
  font-size: 1px;
  color: rgb(255, 255, 255);
}

.progress-circle {
  margin: auto;
}

.create-first-goal {
  text-decoration-line: underline;
}

.underline {
  text-decoration-line: underline;
}

.label {
  font-size: 14px;
}

.item-pad{
  padding-top: 5px;
}

.date-picker {
  width: 90%;
}

.ddd {
  width: 100%;
  --min-width: 250px;
}

.test {
  color: #456673;
  font-size: 13px;
}

.goalFreqTitle {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 5px;
  color: #456673;
}

.createFirstGoal
{
  margin-top: 10px;
  color: #456673;
  font-size: 13px;
}

.center-goals{
  margin: auto;
  padding: 20px;
  width: 450px;
}

.headerButtonWhite {
  color: #07b9ff;
  font-size: 1200px;
  --color: white; 
}

.white {
  --color: white;
}

