.bolding{
    font-weight: bold !important;
}

.radio-no-background{
    background: rgba(255, 0, 0, 0) !important;
}

/* center everything */
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

  
  .star {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 40px;
  }
  
  .starViewOnly {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 19px;
  }

  .on {
    color: #f0ad4e;
  }
  
  .off {
    color: #ccc;
  }

  .submitFeedbackButton{
    margin-bottom: 50px;
  }

  .textarea {
    border: rgb(145, 145, 145) 1px solid;
    border-radius: 10px;
    padding: 5px;
  }

  .itemBackgroundFix{
    --background: #eeeeee00;
  }