.koroQuestionToolbar {
    --background: rgba(255, 255, 255, 0);
    margin-top: 10px;
}


.koroQuestionToolbarWords{
    font-size: 20px;
}

.colorPrimary{
    color: var(--ion-color-primary);
}

