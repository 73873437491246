.terms-wrapper {
  color: rgb(59, 59, 59);
  text-align: justify;
}

.terms-padding {
  padding: 15px;
}
.popup-wrapper {
  color: rgb(59, 59, 59);
  text-align: start;
}
