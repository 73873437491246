.setAvatarPhoto {
    width: 130px;
    height: 130px;
    margin: 0 auto;
}

.setAvatarPhotoSelected {
    border: 3px solid var(--ion-color-primary);
}

.center {
    text-align: center;
}

.photoSpinner
{
    width: 120px;
    height: 120px;
}

.setPublicAvatarContainer {
    padding: 0 20px;
    margin: 0 auto;
}

.addOrEditAvatar{
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
    color: #58bca4;
}

.displayName {
    color: var(--ion-color-primary);
    font-weight: normal;
}