.metric-label {
  font-size: 13px;
  font-weight: 500;
}

.metric-input {
    --background : rgb(241, 241, 241);
    border: 1px solid #456673;
    border-radius: 5px;
    max-width: 100px;
    max-height: 30px;
    padding: 5px;
    
}

.metric-input-bp {
    padding: 0px;
    font-size: 13px;
}

.metric-unit {
    font-size: 9px;
}

ion-grid {
    padding: 0px;
    margin: 0px;
}

ion-row {
    padding: 0px;
    margin: 0px;
}

ion-col {
    padding-left: 0px;
    margin: 0px;
}
