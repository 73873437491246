.telemedicineCard {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    margin: auto;
    margin-top: 20px;
}