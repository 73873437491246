.explore-picture {
  width: 70%;
  height: 700%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
}

.explore-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.explore-text {
  font-size: 15px;
  margin-top: 10px;
}

.blurb {
  font-size: 10px;
  color: blueviolet;
}

.content-thumb {
  margin-right: 15px;
}

.content-title {
  font-size: 20px;
  margin-top: 10px;
}

.content-button {
  min-width: 50px;
}

.view-all {
  color: rgb(29, 11, 225);
}

.exploreSectionTitles {
  text-align: start;
  font-size: large;
  font-weight: bold;
}

.post-img-thumbnail {
  max-width: 300px;
}

.post-card-title {
  font-size: 16px;
  font-weight: bold;
}

.post-card-subtitle {
  font-size: 11px;

}
.recForYouImg{
  float: left;
  height: 200px;
  object-fit: cover;
}

.recForYouCard {
  display: flex;
  flex-wrap: wrap;
  width: 300px;

}

.recForYouCard {
  flex-shrink: 100 !important;

}

.recentWorkout-title {
  font-size: 16px;
}

.recentWorkoutImg{
  float: left;
  width:  200px;
  height: 100px;
  object-fit: cover;
}

.recentWorkoutCard {
  display: flex;
  flex-wrap: wrap;

}

.recentWorkoutCard {
  flex-shrink: 100 !important;
  width: 175px;

}


.categorySlides {
  flex-shrink: 100 !important;
  width: 100px;
  max-width: 120px;
}

.recipeImg{
  float: left;
  width:  100px;
  height: 100px;
  object-fit: cover;
}

.recipeGrid {
  --ion-grid-padding: 0px;
  --ion-grid-margin: 0px;
}

.recipeNoPadding {
  padding: 0px;
  margin: 0px;
}

.recipeCard {
  max-width: 400px;
}

.backgroundTest {
  background-color: #b60909;
}

.recipeWords {
  padding-top: 20px;
  text-align: center;
}


.imgContainerForPost {
  position: relative;
}
.playBtn {
  position: absolute;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  margin-left: -48px; /*half of the width */
  margin-top: -48px; /*half of the height */
}
