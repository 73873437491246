.koroMessageBoxItem {
    --background: #e8f3f191;
    --border-radius: 20px;
}

.whatToShare {
    font-size: 15px;
    color: #8e8e8e;   
}

.koroAskQuestionTitle
{
font-size: 25px;
font-weight: bold;
}

.koroAddImageLink
{
    font-size: 13px;
    color: #007aff;
    text-decoration: underline;
}